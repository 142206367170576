/*eslint-disable */

import { bus } from '@/main';
import countries from '@/services/country.json';
import toast from '@/services/toast';

export default {
  name: 'support-group-form',
  components: {},
  props: [],
  data () {
    return {
      countryList: [],
      flagTypeOfForm:'Support Group',
      cityOfSupportGroup:'',
      supportGroupTitle:'',
      associatedWithSupportGroup:'',
      countryOfSupportGroup:'',
      cityOfSupportGroup:'',
      supportGroupDesc:'',
      submitted:false,
      anyoneJoinStatus:true,
      typeOfSupportGroup:'',
      sgDocument:null,
      sgFileName:'',
    }
  },
  computed: {

  },
  mounted () {
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key],
        value: key
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
  },
  methods: {
    handleAttachmentUpload(event) {
      if(event.target.files && event.target.files.length > 0) {
        this.sgDocument = event.target.files[0];
        this.sgFileName = this.sgDocument.name
      }else {
        this.sgFileName = '';
      }
    },
    updateSupportGroupInfoBeforeSubmitMethod(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted =  true
          var formData = new FormData();
          formData.append('title', this.supportGroupTitle);
          formData.append('associated_with', this.associatedWithSupportGroup);
          formData.append('country', this.countryOfSupportGroup);
          formData.append('city', this.cityOfSupportGroup);
          formData.append('description', this.supportGroupDesc);
          formData.append('flag_type_of_form', this.flagTypeOfForm);
          formData.append('anyone_join', this.anyoneJoinStatus);
          formData.append('type', this.typeOfSupportGroup);
          if(this.sgFileName){
            formData.append('proof', this.sgDocument);
            formData.append('filename', this.sgFileName);
          }
          this.$store.dispatch('registerSupportGroupFun', formData)
            .then((res) => {  
              toast.success(res.data.msg);
              let notificationPayload = { 
                type:'sg_created',
                data_id:res.data.data.id,
                message:"Support Group has been created"
              }
              // this.$store.dispatch('postNotificationFunc', notificationPayload)      
              this.submitted =  false
              // this.$store.dispatch('getSupportGroupListFunc')
              bus.$emit('SGSignUpFormPopupBus', true);
              this.closeUpdateAboutUserInfoPopup()
            }).catch((err) => {
              toast.error(err.data.data.user_msg || err.data.user_msg || err.data.msg);
              this.submitted =  false
            })
          return;
        }
      })
    },
    closeUpdateAboutUserInfoPopup(){
      document.body.classList.remove('popup_open_body')
      bus.$emit('ngoSignUpFormPopupBus', false);
    },
  }
}